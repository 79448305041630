import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../models/auth.model';
import { UsersTable } from '../../../../_fake/users.table';
import { UserService } from 'src/app/services/users.service';
import {
  ForgotPasswordModel,
  ResetPassword,
} from '../../models/forgot-password.model';
import { CreateUser } from '../../models/create-user';

//const API_USERS_URL = `${environment.apiUrl}/auth`;
const API_USERS_URL = `${environment.api}users`;
const API_AUTH_URL = `${environment.api}baseauthentication`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  http: HttpClient;
  //constructor(private http: HttpClient) {}
  constructor(httpBackend: HttpBackend, private userService: UserService) {
    this.http = new HttpClient(httpBackend);
  }
  // public methods
  login(UserName: string, Password: string): Observable<any> {
    return this.http.post<AuthModel>(`${API_AUTH_URL}/loginV2`, {
      UserName,
      Password,
    });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }
  verifyEmail(verificationToken: string): Observable<any> {
    return this.http.put<any>(
      `${environment.api}verify-email?token=${verificationToken}`,
      verificationToken
    );
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(
    forgotPasswordModel: ForgotPasswordModel
  ): Observable<ForgotPasswordModel> {
    return this.http.post<ForgotPasswordModel>(
      `${environment.api}forgot-password`,
      forgotPasswordModel
    );
  }
  resetPassword(resetPassword: ResetPassword): Observable<ResetPassword> {
    return this.http.put<ResetPassword>(
      `${environment.api}reset-password`,
      resetPassword
    );
  }

  getUserByToken(
    token: string,
    email: string,
    username: string,
    firstName: string,
    lastName: string,
    accessLevel: number
  ): Observable<UserModel> {
    let user = new UserModel();
    this.getUserByEmail(email, token).subscribe((result) => {
      if (result) {
        user.setUser(result);
        // this.authService.currentUserValue(user);
        // console.log('getUserByEmailHardCoded', user);
      }
    });

    if (!user) {
      return of(new UserModel());
    }

    return of(user);
  }

  getUserByEmail(
    email: string,
    token: string
  ): Observable<UserModel | undefined> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const payload = { email: email };
    return this.http
      .post<UserModel>(`${API_USERS_URL}/email`, payload, {
        headers: httpHeaders,
      })
      .pipe(catchError(() => of(undefined)));
  }
}
