//import { Injectable } from '@angular/core';
//import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Observable, of } from 'rxjs';
//import { environment } from '../../environments/environment';
//import { User } from '../dtos/user/User';
//import { UserDto } from '../dtos/user/UserDto';
//import { Case } from '../dtos/case/Case';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UpdateUserDto, User, UserDto, UserRole } from '../dtos/user/UserDto';
import { CreateUser } from '../modules/auth/models/create-user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  private apiUrl = environment.api + 'users';

  constructor(private http: HttpClient) {}

  getUsersV1(): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiUrl}`, this.httpOptions);
  }

  getUserById(userId: number): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/${userId}`, this.httpOptions);
  }

  getUserByEmail(email: string): Observable<UserDto> {
    const payload = { email: email };
    return this.http.post<UserDto>(
      `${this.apiUrl}/email`,
      payload,
      this.httpOptions
    );
  }

  getUserAllRoles(): Observable<UserRole> {
    // const payload = { role: role };
    return this.http.get<UserRole>(`${this.apiUrl}/roles`, this.httpOptions);
  }

  createUser(newUser: UserDto): Observable<UserDto> {
    return this.http.post<UserDto>(this.apiUrl, newUser, this.httpOptions);
  }
  
  createUserV2(user: CreateUser): Observable<CreateUser> {
    return this.http.post<CreateUser>(`${environment.api}register`, user);
  }

  updateUser(userId: number, userToUpdate: UpdateUserDto): Observable<void> {
    return this.http.put<void>(
      `${this.apiUrl}/${userId}`,
      userToUpdate,
      this.httpOptions
    );
  }

  updatePassword(
    userId: number,
    oldPassword: string,
    newPassword: string
  ): Observable<void> {
    const payload = { password: oldPassword, newPassword };
    return this.http.put<void>(`${this.apiUrl}/${userId}/password`, payload);
  }
  comparePasswords(userId: number, password: string): Observable<boolean> {
    const payload = { password };
    return this.http.post<boolean>(
      `${this.apiUrl}/${userId}/compare-passwords`,
      payload
    );
  }

  deleteUser(userId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${userId}`);
  }

  // Coreact Methods
  getCorrectPassword(userId: number): Observable<{ password: string }> {
    return this.http.get<{ password: string }>(
      `${this.apiUrl}/${userId}`,
      this.httpOptions
    );
  }
}
